$color-navbar: #363E47;
$breakpoints: (
        mobile: 599px
);
$color-shadow: #C5CACC;
$color-text: #5F6A7D;
$color-green: #4CBC29;
$color-green-active: #317a1b;
$color-blue: #4CBCD2;
$color-blue-active: #327C89;
$color-turquoise: #00ffff;
$color-orange: #F1A630;
$color-orange-active: #BA7E25;
$color-red: #E1325B;
$color-red-active: #A02443;

$small:600;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
  background-color: white;
  // background-image: url(./../images/wallpaper.jpg);
  background: #98ccec;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #636A6E;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.bg-green {
  background-color: white;
  color:black;
}
//add
.bg-green:hover{
  background-color: #19AB27; 
  color:white; //colour text
}

.bg-darkgreen {
  background-color: $color-green-active;
}

.bg-blue {
  background-color: $color-blue;
}

.bg-turquoise {
  background-color: $color-turquoise;
}

.bg-orange {
  background-color: $color-orange;
}

.bg-red { 
  background-color: white;
  color:black;
}

.bg-red:hover{
  background-color: #E1325B; 
  color:white; //colour text
}

.bg-reddel { //add utk asingkan button delete
  background-color: $color-red;
}

.toolsPanel {
  position: absolute;
  top: 24px;
  right: 16px;

  span {
    margin-right: 8px;
  }

  @media screen and (max-width: 1540px) {
    top: 0;
    right: 0;
    span {
      margin-right: 0px;
    }
    span a {
      border-radius: 0;
      border: none;
    }
  }

  @media screen and (max-width: map-get($breakpoints, mobile)) {
    span {
      display: block;

      a {
        span {
          float: right;
        }
      }
    }
  }
}

.mt-onsm {
  @media screen and (max-width: map-get($breakpoints, mobile)) {
    margin-top: 1rem !important;
  }
}

.mb-onsm {
  @media screen and (max-width: map-get($breakpoints, mobile)) {
    margin-bottom: 1rem !important;
  }
}

.otjs-btn-tools {
  display: inline-block;
  font-weight: 400;
  color: white;
  width: 6.5rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid white;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &-blue {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-active;
      color: white;
    }
  }

  &-orange {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-active;
      color: white;

    }
  }

  &-red {
    background-color: $color-red;

    &:hover {
      background-color: $color-red-active;
      color: white;

    }
  }

  &-round {
    border-radius: 38px;
  }
}

.otjs-card {
  background-color: white;
  position: absolute;
  left: 18%;
  width: 79%;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 2px 2px 3px $color-shadow;

  @media screen and (max-width: map-get($breakpoints, mobile)) {
    left: 10%;
    width: 80%;
  }

}

.navbar {
  margin-left: 5%;
  margin-top: 20px;

  @media screen and (max-width: 1540px) {
    margin-top: 32px;
  }
}

.main {
  margin: 5% auto auto auto;
  border: 2px solid white;
  background-color: white;
  padding: 3rem;
  width: 95%;
  border-radius: 8px;
  box-shadow: 4px 4px 4px $color-shadow;
  transition: 1s;

  .content {
    h2 {
      padding-bottom: 2rem;
    }
  }
}

.otjs-button {
  display: inline-block;
  font-weight: 400;
  color: white;
  width: 6rem;
  height: 3rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid white;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1.5rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &-blue {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-active;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(76, 188, 210, 0.5);
    }
  }

  &-orange {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-active;
      color: white;

    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(241, 166, 48, 0.5);
    }
  }

  &-red {
    background-color: $color-red;

    &:hover {
      background-color: $color-red-active;
      color: white;

    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(225, 50, 91, 0.5);
    }
  }

  &-green {
    background-color: $color-green;

    &:hover {
      background-color: $color-green-active;
      color: white;

    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(76, 188, 41, 0.5);
    }
  }

}


.button-dropdown {
  color: white;
  border: 1px solid white;
  width: 6rem;
  height: 3rem;
  border-radius: 1.5rem;

  &-blue {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-active;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(76, 188, 210, 0.5);
    }
  }

  &-orange {
    background-color: $color-orange;

    &:hover {
      background-color: $color-orange-active;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(241, 166, 48, 0.5);
    }
  }

  &-red {
    background-color: $color-red; //change 4.8

    &:hover {
      background-color: $color-red-active;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(225, 50, 91, 0.5);
    }
  }

  &-green {
    background-color: $color-green;

    &:hover {
      background-color: $color-green;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(76, 188, 41, 0.5);
    }
  }
}

#showResult {
  visibility: hidden;
  transition: 0.5s;
  opacity: 0;
  height: 0;
}

#showResult.show-result-opened {
  visibility: visible;
  transition: 0.5s;
  opacity: 1;
  height: auto;
}

.popover {
  width: 33%;
}

.w {
  &-7 {
    width: 7rem;
  }

  &-10 {
    width: 10rem;
  }

  &-12 {
    width: 12rem;
  }
}

.otjs-navmenu {
  display: flex;
  flex-wrap: wrap;
  padding-top: .5rem;
  padding-bottom: .5rem;

  &-nav {
    border-bottom: solid 2px #a7adb6;
    display: flex;
    align-content: center;
    justify-content: space-around;
    width: 100%;
    list-style: none;

    &-link {
      padding-left: 1rem;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      padding-right: 1rem;
      font-size: 16px;

      .disabled {
        font-style: 16.5px;
      }

      &:hover {
        font-size: 16.5px;
      }
    }
  }

}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  color: #2871c4;

  &-active {
    font-weight: bold;
    border-left: solid 2px #a7adb6;
    border-right: solid 2px #a7adb6;
    border-top: solid 2px #a7adb6;
    border-radius: 5px 5px 0 0;
  }
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.footer {
  position: relative;
  margin-top: 4rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &-text {
    color: white;
    font-weight: 600;
    font-size: 14px;
  }
}

.ico {
  background-image: url(./../images/wallpaper.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  font-size: 20px;
  border-radius: 50%;
  color: white;

}

.sub-btn-admin {
  background: none;
  border: none;
  outline: inherit;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: $color-blue;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 18px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    color: $color-blue-active
  }

  &-active {
    font-weight: bold;
    color: $color-blue-active;
  }
}


.dropzone {
  margin: 16px 0;
  height: 100px;
  width: 100%;
  color: rgb(241, 30, 76);
  line-height: 85px;
  text-align: center;
  background-color: rgb(255, 241, 244);
  border-radius: 16px;
  border: 5px dashed rgb(209, 19, 63);
  cursor: pointer;
}

.dropzone.dz-parsing {
  transition: 0.1s ease-in-out;
  box-shadow: inset 0 0 35px rgba(255, 10, 67, 0.2);
  border: 5px solid rgba(175, 54, 82, 0.8);
}


.authentification {
  // background-image: url(./../images/wallpaperAuth.jpg);
  background: #98ccec;
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn't move when
      the content's height is greater than the image's height */
  background-attachment: fixed;
  /* This is what makes the background image rescale based
      on the container's size */
  background-size: cover;
}

a {
  color: #00B7FF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.block {
  margin: 0px auto;
  background-color: ghostwhite;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.block-400 {
  width: 450px;
  @media screen and (max-width:1100px) {
    width: 400px;
  }
  @media screen and (max-width:600px) {
    width: 95%;
  }
}

.block-title {
  padding: 8px 18px;
  text-align: center;
  font-family: SourceSansProItalic;
  font-size: 24px;
  color: white;
  background-color: #2ab3dd;
  border-radius: 8px 8px 0 0;
}

.block-content {
  padding: 40px 50px;
  border-radius:10px;
  margin-top: 40px;
  #login-form{
    fieldset{
      display: flex;
      margin-top: 30px;
      align-items: center;
      border-bottom: 2px solid rgb(151, 146, 146);

      input:-webkit-autofill {
        border: none;
        background: transparent;
        /* webkit autofill */
             -webkit-background-clip: text; /* Supprime le background autofill, utile pour le border radius */
    }
      input{
        background-color: transparent !important;
        width: 100%;
        font-size: 1.2rem;
        line-height: 2.5rem;
        border: none;
        outline: none;
        padding-left: 20px;
      }
    }
    .r-and-f{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      .rember-pass{
        display: flex;
        align-items: center;
        label{
          margin-left: 5px;
        }
      }

     
    }

    .login-btn{
      width: 100%;
      background: #e8ecec;
      margin-top: 40px;
      border: none;
      outline: none;
      padding: 1rem;
      font-size: 18px;
      font-weight: bold;
      color: #1e81b0;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width:600px) {
    padding: 30px 35px;
  }
}

.block-panel {
  margin: auto 16px;
  flex: 1;
}

.block-panel-title {
  color: #1F5EA7;
  font-size: 16px;
  font-family: SourceSansProBold;
}


.main-content {
  padding: 12px 64px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: all 500ms
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  transform: translateX(-100%);
  transition: all 500ms

}

.auth-enter {
  opacity: 0%;
}

.auth-enter-active {
  opacity: 1;
  transition: opacity 1500ms;
}

.auth-exit {
  opacity: 0;
}

.auth-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

//rishabh changes for nav link
.nav-link{
  font-weight: 500 !important;
  color: rgb(37, 36, 36) !important;
}

.nav-link.active {
  font-weight: bold !important;
  color: rgba(255, 255, 255, 1) !important;
  text-shadow: 0px 0px 2px grey;
  font-size: 15px;
}

.nav-link.disabled {
  color: rgba(128, 128, 128, 1) !important;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
  justify-content: center;

  #login{
   
    @media screen and (max-width:1150px){
      margin-top: 0%;
      #logo-login{
        height: 150px;
        margin-bottom: -30px;
      }
    }
    @media screen and (max-width:600px){
      #logo-login{
        height: 140px !important;
        margin-bottom: -20px;
      }
    }
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.grid-form-group {
  display: grid;
}

.react-bootstrap-table.table-responsive {
  overflow-y: visible;
}

@media (min-width: 400px) {
  .grid-form-group {
    grid-template-columns: 200px 1fr;
    grid-gap: 16px;
  }

  .grid-form-group label {
    grid-column: 1 / 2;
  }

  .grid-form-group input, .select {
    grid-column: 2 / 3;
  }
}

.grid-button-group {
  display: grid;
  max-width: 400px;
}


@media (min-width: 400px) {
  .grid-button-group {
    grid-template-columns: 200px 1fr;
    grid-gap: 16px;
  }

  .grid-button-group p {
    grid-column: 1 / 2;
  }

  .grid-button-group input {
    grid-column: 3 / 4;
  }
}

.label-dropdown-item {
  min-width: 350px;
  width: 100%;
}

.form-control.form-control-search-select {
  padding: 0;
}

.form-control.form-control-search-select > input {
  margin: -1px;
  border: 1px solid #ced4da;;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: .25rem;
}

.form-control.form-control-search-select:not(:first-child) > input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-control.form-control-search-select:not(:last-child) > input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.form-control.form-control-search-select > input:focus-visible {
  margin: -1px;
  border: 2px solid #80bdff;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(0, 123, 255 / 25%);
}

.form-control.form-control-search-select > div {
  background-color: white;
  border: 1px solid #ced4da;
  margin-top: -4px;
  margin-left: -1px;
  margin-right: -1px;
  border-radius: 0 0 4px 4px;
}

.label-dropdown-item:first-child {
  border-bottom: 1px solid lightgrey;
}


.text-light-grey {
  color: white;
  background-color: #a0a0b0;
  border-radius: 4px;
  text-align: center;
  min-width: 1.5em;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

button > p {
  margin: 0;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: visible;
}

.table-responsive td {
  min-height: 1px;
  height: 1px;
}

.table-responsive .expand-cell {
  width: 100%;
  height: 100%;
  font-size: 1.4em;
}

.react-select {
  min-width: 120px;
}

.modal-xxl {
  max-width: none;
  margin-left: 25px;
  margin-right: 25px;
}
